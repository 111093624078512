body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

p {
  color: #666;
}

.error {
  color: red;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.refresh-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.copy-button {
  padding: 10px 20px;
  background-color: #74adeb;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.refresh-button:hover,
.copy-button:hover {
  background-color: #0056b3;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  user-select: none; /* Previene la selección de texto en toda la tabla */
}

th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  position: relative;
  font-size: 12px; /* Añadido font-size de 12px */
}

th {
  background-color: #007bff;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

td {
  background-color: #fff;
  cursor: default; /* Cambiado a cursor por defecto */
}

.selected {
  background-color: #b3d4fc;
}

td,
th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: auto;
}

td * {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

/* Estilo para el mensaje de espera */
.waiting-message {
  font-size: 14px;
  color: #999;
  margin-top: 5px;
}

/* Responsive para pantallas más pequeñas */
@media screen and (max-width: 600px) {
  .App {
    padding: 10px;
  }

  .button-container {
    flex-direction: column;
    gap: 5px;
  }

  table {
    font-size: 12px;
  }

  th,
  td {
    padding: 8px;
  }
}
